import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';

export default function GreenHousePlanVsActualEmissions() {
    const [facilityData, setFacilityData] = useState([]);
    const [pollutantData, setPollutantData] = useState([]);
    const [greenHousePlanVsActualEmissionsData, setGreenHousePlanVsActualEmissionsData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [selectedPollutant, setSelectedPollutant] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);


    useEffect(() => {
        fetchFacilityData();
        fetchPollutantData();
    }, []);



    const handleSearch = (event) => {
        setSearch(event.target.value);
    };


    const fetchFacilityData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityData(data.filter(t => t.facilityCategory != "AMMNET"));
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        }
    };

    const fetchPollutantData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/master/pollutants`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPollutantData(data);
        } catch (error) {
            console.error('Failed to fetch pollutant data:', error);
        }
    };


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFacilityChange = (e) => {
        setSelectedFacilityId(e.target.value);
    };

    const handlePollutantChange = (e) => {
        setSelectedPollutant(e.target.value);
    };


    const handleGreenHousePlanVsActualEmissionsButtonClick = () => {
        if (startDate instanceof Date && endDate instanceof Date && selectedFacilityId !== '') {
            setIsLoading(true); // Set loading state to true before fetching data

            const formattedStartDate = format(startDate, 'yyyy-MM-dd 00:00:00');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd 23:59:59');

            const apiUrl = `${API_BASE_URL}/reports/GHGPlanVsActualEmissions?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&facilityId=${selectedFacilityId}&pollutant=${selectedPollutant}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setGreenHousePlanVsActualEmissionsData(data);
                    setFilteredData(data); // Update the filtered data as well
                    setIsLoading(false); // Set loading state to false after data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set loading state to false if there's an error
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        let filtered = greenHousePlanVsActualEmissionsData.filter(item =>
            String(item.facilityId).toLowerCase().includes(lowercasedSearch) ||
            String(item.actual).toLowerCase().includes(lowercasedSearch) ||
            String(item.planned).toLowerCase().includes(lowercasedSearch) ||
            String(item.ytda).toLowerCase().includes(lowercasedSearch) ||
            String(item.ytdp).toLowerCase().includes(lowercasedSearch) ||
            String(item.variance).toLowerCase().includes(lowercasedSearch)
        );

        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered = [{
                facilityId: '',
                actual: '',
                planned: '',
                ytda: '',
                ytdp: '',
                variance: ''
            }];
        }

        setFilteredData(filtered);

        // Create exportFilteredData according to the columns order
            const exportData = filtered.map(item => ({
                'Facility ID': item.facilityId,
                'Actual': item.actual,
                'Planned': item.planned,
                'YTD(A)': item.ytda,
                'YTD(P)': item.ytdp,
                'Variance': item.variance
            }));
            setExportFilteredData(exportData);

            
    }, [search, greenHousePlanVsActualEmissionsData]);


    const columns = [
        { name: 'Facility ID', selector: row => row.facilityId, sortable: true },
        { name: 'Actual', selector: row => row.actual, sortable: true },
        { name: 'Planned', selector: row => row.planned, sortable: true },
        { name: 'YTD(A)', selector: row => row.ytda, sortable: true },
        { name: 'YTD(P)', selector: row => row.ytdp, sortable: true },
        { name: 'Variance', selector: row => row.variance, sortable: true },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `GHG_Plan_VsActual_Emission${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
        { label: 'Pollutant Name', value: selectedPollutant != -1 ? pollutantData.find(pollutant => pollutant.pollutantid == selectedPollutant)?.pollutantName : 'All Pollutants' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];
    
    const title = "Plan Vs Actual Emission Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Plan Vs Actual Emission</h1>
                <ul>
                    <li>Reports</li>
                    <li>Greenhouse Gases</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Select Facility</label>
                        <select
                            name='facility'
                            className='form-control'
                            value={selectedFacilityId}
                            onChange={handleFacilityChange}
                        >
                            <option value={-1}>All Facilities</option>
                            {facilityData.map((facility) => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='col-md-2'>
                        <label>Select Pollutant</label>
                        <select
                            name='pollutant'
                            className='form-control'
                            value={selectedPollutant}
                            onChange={handlePollutantChange}
                        >
                            {/* <option value={-1}>All Pollutants</option> */}
                            {pollutantData.map((pollutant) => (
                                <option value={pollutant.pollutantid}>
                                    {pollutant.pollutantName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='col-md-2'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>

                    </div>
                    <div className='col-md-2'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-1 generate_report_button'>
                        <button className='btn btn-primary' onClick={handleGreenHousePlanVsActualEmissionsButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Plan Vs Actual Emission</h4> */}
                            <div className='row'>
                                            <div className='col-md-10'>
                                                {filteredData.length > 0 && (
                                                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title}/>
                                                )}
                                            </div>
                                            <div className='col-md-2'>
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="form-control mb-3"
                                                    value={search}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination={false}
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
        </div>
    );
}
