import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData, rightAlignNumberFormatHeader } from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';

export default function GreenHouseInventoryByScope() {
    const [greenHouseInventoryByScopeData, setGreenHouseInventoryByScopeData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);



    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };



    const handleGreenHouseInventoryByScopeButtonClick = () => {
        if (startDate instanceof Date && endDate instanceof Date) {
            setIsLoading(true); // Set loading state to true before fetching data
            const formattedStartDate = format(startDate, 'yyyy-MM-dd 00:00:00');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd 23:59:59');
            const apiUrl = `${API_BASE_URL}/reports/GetGhgInventoryScope?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setGreenHouseInventoryByScopeData(data);
                    setFilteredData(data); // Update the filtered data as well
                    setIsLoading(false); // Set loading state to false after data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set loading state to false if there's an error
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        let filtered = greenHouseInventoryByScopeData.filter(item =>
            String(item.sourceLayerID).toLowerCase().includes(lowercasedSearch) ||
            String(item.sourceName).toLowerCase().includes(lowercasedSearch) ||
            String(item.scope1).toLowerCase().includes(lowercasedSearch) ||
            String(item.scope2).toLowerCase().includes(lowercasedSearch) ||
            String(item.scope3).toLowerCase().includes(lowercasedSearch)
        );

        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered = [{
                sourceLayerID: '',
                sourceName: '',
                scope1: '',
                scope2: '',
                scope3: ''
            }];
        }

        setFilteredData(filtered);

        // Create exportFilteredData according to the columns order
        const exportData = filtered.map(item => ({
            'Source Layer ID': item.sourceLayerID,
            'Source Name': item.sourceName,
            'Scope 1': formatWithIndianThousandsSeparatorForExportData(Number(item.scope1).toFixed(2)),
            'Scope 2': formatWithIndianThousandsSeparatorForExportData(Number(item.scope2).toFixed(2)),
            'Scope 3': formatWithIndianThousandsSeparatorForExportData(Number(item.scope3).toFixed(2))
        }));
        setExportFilteredData(exportData);

    }, [search, greenHouseInventoryByScopeData]);


    const columns = [
        { name: 'Source Layer ID', selector: row => row.sourceLayerID, sortable: true },
        { name: 'Source Name', selector: row => row.sourceName, sortable: true },
        {
            name: rightAlignNumberFormatHeader('Scope 1'),
            selector: row => row.scope1,
            sortable: true,
            cell: row => formatWithIndianThousandsSeparator(Number(row.scope1).toFixed(2))
        },
        {
            name: rightAlignNumberFormatHeader('Scope 2'),
            selector: row => row.scope2,
            sortable: true,
            cell: row => formatWithIndianThousandsSeparator(Number(row.scope2).toFixed(2)) 
        },
        {
            name: rightAlignNumberFormatHeader('Scope 3'),
            selector: row => row.scope3,
            sortable: true,
            cell: row => formatWithIndianThousandsSeparator(Number(row.scope3).toFixed(2))
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `GHG_Inventory_By_Scope${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];

    const title = "Inventory by Scope Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Inventory By Scope (ton)</h1>
                <ul>
                    <li>Reports</li>
                    <li>Greenhouse Gases</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-4'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-1 generate_report_button'>
                        <button className='btn btn-primary' onClick={handleGreenHouseInventoryByScopeButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Inventory By Scope</h4> */}
                            <div className='row'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} headerData={headerData} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>


                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
        </div>
    );
}

