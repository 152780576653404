import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PlantSelector from '../../common/PlantSelector';
import DataCollectionModeSelector from '../../common/DataCollectionModeSelector';
import DeviceSelector from '../../common/DeviceSelector';
import { API_BASE_URL } from '../../../config';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function GhgHealthCheck() {
  const [facilities, setFacilities] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [disableCollectionMode, setDisableCollectionMode] = useState(false);
  const [disableDevice, setDisableDevice] = useState(false);
  const [disablePlant, setDisablePlant] = useState(true);
  const [hourlyEmissionData, setHourlyEmissionData] = useState([]);
  const [transactionErrorsData, setTransactionErrorsData] = useState([]);
  const [associatedTagsData, setAssociatedTagsData] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  });
  const [selectedFacilityId, setSelectedFacilityId] = useState('-1');
  const [selectedModeId, setSelectedModeId] = useState('-1');
  const [selectedDeviceId, setSelectedDeviceId] = useState('-1');
  const [selectedPlantId, setSelectedPlantId] = useState('-1');
  const [search, setSearch] = useState('');
  const [facilityData, setFacilityData] = useState([]);
  const [plantsData, setPlantsData] = useState([]);
  const [dataCollectionModes, setDataCollectionModes] = useState([]);
  const [header, setHeader] = useState([]);
  const [ghgDevice, setGhgDevice] = useState([]);
  const [emsDevice, setEmsDevice] = useState([]);
  const [deviceConfigData, setDeviceConfigData] = useState([]);
  const [deviceConfigTwoData, setDeviceConfigTwoData] = useState([]);

  const agGridRef = useRef(null);

  const [deviceConfigColumnDefs, setDeviceConfigColumnDefs] = useState([]);
  const [deviceConfigColumnTWoDefs, setDeviceConfigColumnTwoDefs] = useState(
    []
  );

  const [associatedTagsColumnDefs, setAssociatedTagsColumnDefs] = useState([]);
  const [hourlyEmissionColumnDefs, setHourlyEmissionColumnDefs] = useState([]);
  const [transactionErrorsColumnDefs, setTransactionErrorsColumnDefs] =
    useState([]);

  useEffect(() => {
    fetchFacilities();
    fetchInitialData();
    initializeColumnDefs();
  }, []);

  const fetchFacilities = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/home/facilities`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFacilities(data);
    } catch (error) {
      console.error('Failed to fetch facilities data:', error);
    }
  };

  const handleFacilityChange = (e) => {
    const facId = e.target.value;
    setSelectedFacilityId(facId);
    if (facId === '-1') {
      setDisablePlant(true);
      setSelectedPlantId('-1');
    } else {
      setDisablePlant(false);
    }
  };

  const initializeColumnDefs = () => {
    setDeviceConfigColumnDefs([
      {
        headerName: '',
        field: 'col1',
        cellStyle: { fontWeight: 'bold' },
        width: '250px',
      },
      { headerName: '', field: 'col2', width: '200px' },
      {
        headerName: '',
        field: 'col3',
        cellStyle: { fontWeight: 'bold' },
        width: '250px',
      },
      { headerName: '', field: 'col4', width: '200px' },
      {
        headerName: '',
        field: 'col5',
        cellStyle: { fontWeight: 'bold' },
        width: '250px',
      },
      { headerName: '', field: 'col6', flex: 1 },
    ]);

    setDeviceConfigColumnTwoDefs([
      { headerName: 'Method', field: 'Method' },
      { headerName: 'Pollutant', field: 'Pollutant' },
      { headerName: 'Mes Type', field: 'MesType' },
      { headerName: 'UoM', field: 'UoM' },
      { headerName: 'Scope', field: 'Scope' },
      { headerName: 'Level 1', field: 'Level1' },
      { headerName: 'Level 2 ', field: 'Level2' },
      { headerName: 'Level 3 ', field: 'Level3' },
      { headerName: 'Level 4 ', field: 'Level4' },
      { headerName: 'Factor', field: 'Factor' },
      { headerName: 'Factor Units', field: 'FactorUnits' },
      { headerName: 'Component ID', field: 'ComponentID' },
    ]);
    setAssociatedTagsColumnDefs([
      { headerName: 'Tag Name', field: 'tagName' },
      { headerName: 'Reading Type', field: 'readingType' },
      { headerName: 'Min', field: 'min' },
      { headerName: 'Max', field: 'max' },
      { headerName: 'Default', field: 'defaultValue' },
      { headerName: 'UoM', field: 'uom' },
      { headerName: 'LIMS Composition', field: 'limsComposition' },
      { headerName: 'LIMS Property', field: 'limsProperty' },
      { headerName: 'MW g/mol', field: 'mw_gmol' },
      { headerName: 'LHV BTU/SCF', field: 'lhv_Btuscf' },
    ]);

    setHourlyEmissionColumnDefs([
      {
        headerName: 'Tx Date',
        field: 'txDatetime',
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      { headerName: 'Pollutant', field: 'pollutant' },
      { headerName: 'UoM', field: 'uomId' },
      { headerName: 'Emission', field: 'emission' },
      { headerName: 'Avg', field: 'emissionAverage' },
      { headerName: 'Min', field: 'emissionMinimum' },
      { headerName: 'Max', field: 'emissionMaximum' },
      { headerName: 'Violation Count', field: 'violationCount' },
      { headerName: 'Reasons', field: 'reason' },
    ]);

    setTransactionErrorsColumnDefs([
      {
        headerName: 'Tx Date',
        field: 'transactionDateTime',
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      { headerName: 'Source', field: 'tagname' },
      { headerName: 'Error Messages', field: 'errorComments' },
      { headerName: 'Error Code', field: 'codeLineNo', flex: 1 },
    ]);
  };

  const fetchInitialData = async () => {
    try {
      const facilities = await fetch(`${API_BASE_URL}/home/facilities`).then(
        (res) => res.json()
      );
      const plants = await fetch(`${API_BASE_URL}/home/plants`).then((res) =>
        res.json()
      );
      const devices = await fetch(
        `${API_BASE_URL}/configuration/data/device/all`
      ).then((res) => res.json());
      const dataCollectionModes = await fetch(
        `${API_BASE_URL}/master/code-master/dataCollectionMode`
      ).then((res) => res.json());
      const headers = await fetch(`${API_BASE_URL}/home/headers`).then((res) =>
        res.json()
      );
      const ghgDevices = await fetch(
        `${API_BASE_URL}/device/ghg-device/all`
      ).then((res) => res.json());
      const emsDevices = await fetch(
        `${API_BASE_URL}/ems-master/ems-device`
      ).then((res) => res.json());

      setFacilityData(facilities);
      setPlantsData(plants);
      setDeviceData(devices);
      setDataCollectionModes(dataCollectionModes);
      setHeader(headers);
      setGhgDevice(ghgDevices);
      setEmsDevice(emsDevices);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchGhgHealthCheckData = async () => {
    try {
      if (selectedDeviceId !== '-1') {
        const dateStr = selectedDate.toISOString().split('T')[0];
        const [res1, res2, res3] = await Promise.all([
          fetch(
            `${API_BASE_URL}/reports/devicehourlyemission?deviceId=${selectedDeviceId}&fromDate=${dateStr}`
          ),
          // fetch(`${API_BASE_URL}/reports/dailytxfordevicewithdays?deviceId=${selectedDeviceId}&fromDate=${dateStr}`),
          fetch(
            `${API_BASE_URL}/reports/transactionerrors/all?fromDate=${dateStr}&deviceId=${selectedDeviceId}&updatedBy=IPEMS`
          ),
          fetch(
            `${API_BASE_URL}/configuration/data/tagsmaster/all?deviceId=${selectedDeviceId}`
          ),
        ]);
        const data1 = await res1.json();
        const data2 = await res2.json();
        const data3 = await res3.json();
        setHourlyEmissionData(data1);
        setTransactionErrorsData(data2);
        setAssociatedTagsData(data3);

        if (data1.length > 0) {
          const deviceInformation = deviceData.find(
            (device) => device.deviceId === data1[0].deviceId
          );
          if (deviceInformation) {
            if (deviceInformation.materialState === 'GAS') {
              associateColDef('GAS');
            } else if (deviceInformation.materialState === 'LIQUID') {
              associateColDef('LIQUID');
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching GHG health check data:', error);
    }
  };

  useEffect(() => {
    if (selectedDeviceId !== '-1') {
      fetchGhgHealthCheckData();
      updateDeviceConfiguration(selectedDeviceId);
    }
  }, [selectedDeviceId, selectedDate]);

  const handleSearch = (event) => setSearch(event.target.value);
  const handleSelectedDateChange = (date) => setSelectedDate(date);

  const onFacilityIdSelected = (facilityId) => {
    setSelectedFacilityId(facilityId);
    setDisablePlant(facilityId === '-1');
    setSelectedPlantId('-1');
  };

  const onPlantSelected = (plantId) => setSelectedPlantId(plantId);
  const onModesSelected = (modeId) => {
    setSelectedModeId(modeId);
    setDisableDevice(modeId === '-1');
  };

  const onDeviceSelected = (deviceId) => {
    setSelectedDeviceId(deviceId);
    updateDeviceConfiguration(deviceId);
  };

  const updateDeviceConfiguration = (deviceId) => {
    const selectedDevice = deviceData.find(
      (device) => device.deviceId === deviceId
    );
    const selectedFacility = facilityData.find(
      (facility) => facility.facilityId === selectedDevice.facilityId
    );
    const selectedGhgDevice = ghgDevice.find(
      (device) => device.deviceId === selectedDevice.deviceId
    );
    const selectedEmsDevice = emsDevice.find(
      (device) => device.deviceId === selectedDevice.deviceId
    );
    console.log('Selected Device:', selectedDevice);
    if (!selectedDevice) return;

    let GHGData = [
      {
        col1: 'Source ID',
        col2: selectedDevice.deviceId,
        col3: 'Source Type',
        col4: selectedDevice.deviceEmissionType,
        col5: 'Sp. Gravity',
        col6: selectedDevice.gasSpgravity,
      },
      {
        col1: 'Area',
        col2: selectedFacility ? selectedFacility.areaId : '',
        col3: 'Category ID',
        col4: selectedDevice.categoryId,
        col5: 'KM Mixing',
        col6: selectedDevice.kmMixing,
      },
      {
        col1: 'Facility',
        col2: selectedDevice.facilityId,
        col3: 'Calc Method',
        col4: selectedDevice.calcMethod,
        col5: 'Vapour Pressure PSIA',
        col6: selectedDevice.vapourPressurePsia,
      },
      {
        col1: 'Plant',
        col2: selectedDevice.plantId,
        col3: 'Flow Type',
        col4: selectedDevice ? selectedDevice.flowType : '',
        col5: 'AUTO Rated flow',
        col6: selectedDevice.autoRatedFlow,
      },
      {
        col1: 'Material State',
        col2: selectedDevice.materialState,
        col3: 'Constant Flow Qty',
        col4: selectedDevice.constantFlowQty,
        col5: 'AUTO 4flow Units',
        col6: selectedDevice.auto40CV,
      },
      {
        col1: 'Data Collection Mode',
        col2: selectedDevice.dataCollectionMode,
        col3: 'Constant Flow Qty UOM',
        col4: selectedDevice.constantFlowQtyUOM,
        col5: '',
      },
    ];
    console.log('selectedGhgDevice', selectedGhgDevice);

    const GHGTwoData = [
      {
        Method: selectedGhgDevice.ghgMethodId,
        Pollutant: selectedGhgDevice.ghg,
        MesType: selectedGhgDevice.measurementType,
        UoM: selectedGhgDevice.ghGuom,
        Scope: selectedGhgDevice.scope,
        Level1: selectedGhgDevice.categoryLevel1,
        Level2: selectedGhgDevice.categoryLevel2,
        Level3: selectedGhgDevice.categoryLevel3,
        Level4: selectedGhgDevice.categoryLevel4,
        Factor: selectedGhgDevice.ghgFactor,
        FactorUnits: selectedGhgDevice.ghgFactor,
        ComponentID: selectedGhgDevice.componentId,
      },
    ];
    setDeviceConfigData(GHGData);
    setDeviceConfigTwoData(GHGTwoData);
  };

  const associateColDef = (state) => {
    if (state === 'GAS') {
      setAssociatedTagsColumnDefs([
        {
          headerName: '',
          children: [
            {
              headerName: 'Tag Name',
              field: 'tagName',
              width: 150,
              filter: true,
            },
            {
              headerName: 'Reading Type',
              field: 'readingType',
              width: 150,
              filter: true,
            },
          ],
        },
        {
          headerName: 'Associated Tags',
          children: [
            {
              headerName: 'Min',
              field: 'min',
              width: 90,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'Max',
              field: 'max',
              width: 90,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'Default',
              field: 'defaultValue',
              width: 110,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            { headerName: 'UoM', field: 'uom', width: 130, filter: true },
            {
              headerName: 'LIMS Composition',
              field: 'limsComposition',
              width: 170,
              filter: true,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'LIMS Property',
              field: 'limsProperty',
              width: 150,
              filter: true,
            },
            {
              headerName: 'MW g/mol',
              field: 'mw_gmol',
              width: 115,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'LHV BTU/SCF',
              field: 'lhv_Btuscf',
              width: 120,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
          ],
        },
      ]);
    } else if (state === 'LIQUID') {
      setAssociatedTagsColumnDefs([
        {
          headerName: 'Associated Tags',
          children: [
            {
              headerName: 'Tag Name',
              field: 'tagName',
              width: 150,
              filter: true,
            },
            {
              headerName: 'Reading Type',
              field: 'readingType',
              width: 150,
              filter: true,
            },
            {
              headerName: 'Min',
              field: 'min',
              width: 100,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'Max',
              field: 'max',
              width: 100,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'Default Value',
              field: 'defaultValue',
              width: 150,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            { headerName: 'UoM', field: 'uom', width: 150, filter: true },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'LIMS Composition',
              field: 'limsComposition',
              width: 170,
              filter: true,
            },
            {
              headerName: 'LIMS Property',
              field: 'limsProperty',
              width: 150,
              filter: true,
            },
            {
              headerName: 'LHV',
              field: 'lhv',
              width: 150,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'LHV UOM',
              field: 'lhvUom',
              width: 150,
              filter: true,
            },
            {
              headerName: 'Density',
              field: 'density',
              width: 150,
              filter: true,
              valueFormatter: (params) => params.value || '0',
            },
            {
              headerName: 'Density UOM',
              field: 'densityUom',
              width: 150,
              filter: true,
            },
          ],
        },
      ]);
    }
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-3'>
        <h1>GHG Health Check</h1>
        <ul>
          <li>Health Check</li>
        </ul>
        <div className='separator-breadcrumb border-top' />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className='row'>
        <div className='col-sm-2 form-group'>
          <label>Select Facility</label>
          <select
            className='form-control'
            value={selectedFacilityId}
            onChange={handleFacilityChange}
          >
            <option value='-1'>All Facilities</option>
            {facilities.map((fac) => (
              <option key={fac.facilityId} value={fac.facilityId}>
                {fac.facilityName}
              </option>
            ))}
          </select>
        </div>
        <PlantSelector
          selectedFacilityId={selectedFacilityId}
          selectedPlantId={selectedPlantId}
          onChange={onPlantSelected}
          disabled={disablePlant}
        />
        <DataCollectionModeSelector
          selectedModeId={selectedModeId}
          onChange={onModesSelected}
          disabled={disableCollectionMode}
        />
        <div className='col-sm-2'>
          <label>Select Date</label>
          <div className={'datepicker-parent'}>
            <DatePicker
              className='form-control'
              selected={selectedDate}
              onChange={handleSelectedDateChange}
              dateFormat='yyyy-MM-dd'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
            />
          </div>
        </div>
        <DeviceSelector
          selectedDeviceId={selectedDeviceId}
          onChange={onDeviceSelected}
          disabled={disableDevice}
          facilityID={selectedFacilityId === '-1' ? null : selectedFacilityId}
          plantID={selectedPlantId === '-1' ? null : selectedPlantId}
          dataCollectionMode={selectedModeId === '-1' ? null : selectedModeId}
          type='GHG'
        />
      </div>
      <div className='row mt-4'>
        <div className='col-sm-12'>
          <div className='table-responsive'>
            {/* <h5 className='border text-center py-2'>Source Configurations</h5> */}
            <div
              className='ag-theme-alpine'
              style={{ height: 280, width: '100%' }}
            >
              <AgGridReact
                rowData={deviceConfigData}
                columnDefs={deviceConfigColumnDefs}
                defaultColDef={{ resizable: true, sortable: true }}
                headerHeight={-0.5}
              />
            </div>
          </div>
          <br></br>
          <div className='table-responsive'>
            {/* <h5 className='border text-center py-2'>Source Configurations</h5> */}
            <div
              className='ag-theme-alpine'
              style={{ height: 120, width: '100%' }}
            >
              <AgGridReact
                rowData={deviceConfigTwoData}
                columnDefs={deviceConfigColumnTWoDefs}
                defaultColDef={{ resizable: true, sortable: true }}
              />
            </div>
          </div>
          <br></br>
          <div className='table-responsive'>
            <h5 className='border text-center py-2'>Associated Tags</h5>
            <div
              className='ag-theme-alpine'
              style={{ height: 400, width: '100%' }}
            >
              <AgGridReact
                rowData={associatedTagsData}
                columnDefs={associatedTagsColumnDefs}
                defaultColDef={{ resizable: true, sortable: true }}
              />
            </div>
          </div>
          <br></br>
          <div className='table-responsive'>
            <h5 className='border text-center py-2'>
              Hourly Pollutant Emissions
            </h5>
            <div
              className='ag-theme-alpine'
              style={{ height: 400, width: '100%' }}
            >
              <AgGridReact
                rowData={hourlyEmissionData}
                columnDefs={hourlyEmissionColumnDefs}
                defaultColDef={{ resizable: true, sortable: true }}
              />
            </div>
          </div>
          <br></br>
          <div className='table-responsive'>
            <h5 className='border text-center py-2'>Source Alerts</h5>
            <div
              className='ag-theme-alpine'
              style={{ height: 400, width: '100%' }}
            >
              <AgGridReact
                rowData={transactionErrorsData}
                columnDefs={transactionErrorsColumnDefs}
                defaultColDef={{ resizable: true, sortable: true }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
