import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacilitySelector from '../../common/FacilitySelector';
import PlantSelector from '../../common/PlantSelector';
import DataCollectionModeSelector from '../../common/DataCollectionModeSelector';
import ExportComponent from '../../common/ExportComponent';
import DeviceSelector from '../../common/DeviceSelector';
import { API_BASE_URL } from '../../../config';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const tableStyle = {
    color: '#fff',
    backgroundColor: '#333',
};

const thTdStyle = {
    verticalAlign: 'middle',
    textAlign: 'center',
};

const thStyle = {
    backgroundColor: '#444',
    fontWeight: 'bold',
};

const borderedStyle = {
    border: '1px solid #555',
};

const smallPaddingStyle = {
    padding: '0.3rem',
};

export default function FMSHealthCheck() {
    const [facilities, setFacilities] = useState([]);
    const [ghgErrorsData, setGHGErrorsData] = useState([]);
    const [dailyTxDeviceData, setDailyTxDeviceData] = useState([]);
    const [associatedTagsData, setAssociatedTagsData] = useState([]);
    const [fmsData, setFmsData] = useState([]);
    // const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return yesterday;
    });
    const [selectedFacilityId, setSelectedFacilityId] = useState('-1');
    const [selectedModeId, setSelectedModeId] = useState('-1');
    const [selectedDeviceId, setSelectedDeviceId] = useState('-1');
    const [selectedPlantId, setSelectedPlantId] = useState('-1');
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [deviceData, setDeviceData] = useState([]);
    const [filteredDeviceData, setFilteredDeviceData] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [disablePlant, setDisablePlant] = useState(true);
    const [equipmentData, setEquipmentData] = useState([]);
    const [facilityFeedData, setFacilityFeedData] = useState([]);
    const [annualPriceData, setAnnualPriceData] = useState([]);
    const [headerData, setHeaderData] = useState([]);

    const [sourceColumnDefs, setSourceColumnDefs] = useState([]);
    const [associatedTagsColumnDefs, setAssociatedTagsColumnDefs] = useState([]);
    const [flaringColumnDefs, setFlaringColumnDefs] = useState([]);
    const [alertsColumnDefs, setAlertsColumnDefs] = useState([]);

    useEffect(() => {
        fetchFacilities();
        fetchDeviceData();
        fetchInitialData();
        initializeColumnDefs();
    }, []);


    useEffect(() => {
                if (selectedFacilityId !== -1) {
                    const filteredDevice = deviceData.filter(device => device.facilityId == selectedFacilityId);
                    setFilteredDeviceData(filteredDevice);
                } else {
                    setDeviceData([]);
                    setSelectedDeviceId(-1);
                }
            }, [selectedFacilityId]);

    const fetchFacilities = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilities(data);
        } catch (error) {
            console.error('Failed to fetch facilities data:', error);
        }
    };

    const handleFacilityChange = (e) => {
        const facId = e.target.value;
        setSelectedFacilityId(facId);
        if (facId === '-1') {
            setDisablePlant(true);
            setSelectedPlantId('-1');
        } else {
            setDisablePlant(false);
        }
    };

    const initializeColumnDefs = () => {
        setSourceColumnDefs([
            { headerName: '', field: 'col1', cellStyle: { fontWeight: 'bold' }, width:'150px'  },
            { headerName: '', field: 'col2', width:'150px' },
            { headerName: '', field: 'col3', width:'150px' },
            { headerName: '', field: 'col4', width:'150px' },
            { headerName: '', field: 'col5', width:'150px' },
            { headerName: '', field: 'col6', width:'150px', cellStyle: { textAlign: 'right' } },
            { headerName: '', field: 'col7', width:'150px', },
            { headerName: '', field: 'col8', width:'150px', cellStyle: { textAlign: 'right' }},
            { headerName: '', field: 'col9', width:'100px' },
            { headerName: '', field: 'col10', width:'150px', cellStyle: { textAlign: 'right' } },
            { headerName: '', field: 'col11', width:'150px' },
            { headerName: '', field: 'col12', width:'100px', cellStyle: { textAlign: 'right' } },
        ]);

        setAssociatedTagsColumnDefs([
            { headerName: 'Tag Name', field: 'tagName' },
            { headerName: 'Reading Type', field: 'readingType' },
            { headerName: 'Min', field: 'min' },
            { headerName: 'Max', field: 'max' },
            { headerName: 'Default', field: 'defaultValue' },
            { headerName: 'UoM', field: 'uom' },
            { headerName: 'LIMS Composition', field: 'limsComposition' },
            { headerName: 'LIMS Property', field: 'limsProperty' },
            { headerName: 'MW g/mol', field: 'mw_gmol' },
            { headerName: 'LHV BTU/SCF', field: 'lhv_Btuscf' },
        ]);

        setFlaringColumnDefs([
            { headerName: 'Tx Date', field: 'txDateTime', valueFormatter: params => new Date(params.value).toLocaleDateString('sv-SE') },
            { headerName: 'Adj Flare (MSCFD)', field: 'adjustedFlaring' },
            { headerName: 'Adj Flare Oil (BBLD)', field: 'adjustedFlaringOil' },
            { headerName: 'Leakage (MSCFD)', field: 'leakage' },
            { headerName: 'LHV Mixed', field: 'mixedLHV' },
            { headerName: 'Mol Weight Mixed (Kg/Mol)', field: 'mixedMolwt' },
            { headerName: 'Density Mixed (Lb/Gal)', field: 'mixedDensity' },
            { headerName: 'Mass LBD', field: 'massLbd' },
            { headerName: 'Reasons', field: 'reasons' },
        ]);

        setAlertsColumnDefs([
            { headerName: 'Tx Date', field: 'transactionDateTime', valueFormatter: params => new Date(params.value).toLocaleDateString('sv-SE') },
            { headerName: 'Source', field: 'tagname' },
            { headerName: 'Error Messages', field: 'errorComments', width:'1200px' },
            { headerName: 'Error Code', field: 'codeLineNo' },
        ]);
    };

    const fetchInitialData = async () => {
        try {
            const [facilities, equipments, facilityFeeds, annualPrices, headers] = await Promise.all([
                fetch(`${API_BASE_URL}/home/facilities`).then(res => res.json()),
                fetch(`${API_BASE_URL}/equipment/all`).then(res => res.json()),
                fetch(`${API_BASE_URL}/ems-master/facility-feed/all`).then(res => res.json()),
                fetch(`${API_BASE_URL}/master/annual-price`).then(res => res.json()),
                fetch(`${API_BASE_URL}/home/headers`).then(res => res.json()),
            ]);

            setFacilityData(facilities);
            setEquipmentData(equipments);
            setFacilityFeedData(facilityFeeds);
            setAnnualPriceData(annualPrices);
            setHeaderData(headers);
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchDeviceData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/device/all`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setDeviceData(data);
        } catch (error) {
            console.error('Failed to fetch Source Type data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedDeviceId !== '-1') {
            fetchFMSHealthCheckData();
        }
    }, [selectedDeviceId, selectedDate]);

    const fetchFMSHealthCheckData = async () => {
        if (selectedDeviceId !== '-1') {
            setIsLoading(true);
            const dateStr = selectedDate.toISOString().split('T')[0];
            try {
                const [res1, res2, res3] = await Promise.all([
                    fetch(`${API_BASE_URL}/reports/dailytxfordevicewithdays?deviceId=${selectedDeviceId}&fromDate=${dateStr}`),
                    fetch(`${API_BASE_URL}/reports/transactionerrors/all?fromDate=${dateStr}&deviceId=${selectedDeviceId}&updatedBy=IPFMS`),
                    fetch(`${API_BASE_URL}/configuration/data/tagsmaster/all?deviceId=${selectedDeviceId}`)
                ]);
                const data1 = await res1.json();
                const data2 = await res2.json();
                const data3 = await res3.json();

                setDailyTxDeviceData(data1);
                setGHGErrorsData(data2);
                setAssociatedTagsData(data3);
                setFilteredData(data2);

                if (data1.length > 0) {
                    const deviceInformation = deviceData.filter(d => d.deviceId == data1[0].deviceId);
                    const device = deviceInformation[0];
                    const facility = facilityData.find(f => f.facilityId === data1[0].facilityId);
                    const equipment = equipmentData.find(e => e.equipmentId === device.equipmentId);
                    const facilityFeed = facilityFeedData.find(f => f.facilityId === device.facilityId);
                    const annualPrice = annualPriceData.find(a => a.year === '2020');
                    const header = headerData.find(h => h.headerId === device.headerId);

                    const getValidNumber = (value) => {
                        return value && !isNaN(value) ? value : '0';
                    };

                    const fmsData = [
                        { col1: 'Source ID', col2: device.deviceId, col3: 'Device Category', col4: device.categoryId, col5: 'KM Mixing', col6: getValidNumber(device.kmMixing), col7: 'Auto Max Flow CV', col8: device.autoMaxFlowCV, col9: 'Equipment', col10: device.equipmentId, col11: 'Auto 0 C', col12: device.auto0CV || '0' },
                        { col1: 'Source Type', col2: device.deviceEmissionType, col3: 'Flow Type', col4: device.flowType, col5: 'Vapour Pressure PSIA', col6: getValidNumber(device.vapourPressurePsia), col7: 'Auto Rated Flow CV', col8: device.autoRatedFlowCV, col9: 'Set Point Pressure', col10: device.setPointPressure || '0', col11: 'Auto 10 C', col12: device.auto10CV || '0' },
                        { col1: 'Area', col2: facility ? facility.areaId : '', col3: 'Device Mode', col4: device.deviceMode, col5: 'YC Constant', col6: getValidNumber(device.ycConstant), col7: 'Auto CVtoFlowMethod', col8: device.cvCalculationMethod, col9: 'Operating Pressure Max', col10: equipment ? equipment.operatingPressureMax : '', col11: 'Auto 20 C', col12: device.auto20CV || '0' },
                        { col1: 'Facility', col2: device.facilityId, col3: 'Material State', col4: device.materialState, col5: 'Valve Manufacturer', col6: getValidNumber(device.valveManufacturer), col7: 'Auto Min Flow', col8: device.autoMinFlow, col9: 'Operating Pressure UoM', col10: equipment ? equipment.operatingPressureUom : '', col11: 'Auto 30 C', col12: device.auto30CV || '0' },
                        { col1: 'Plant', col2: device.plantId, col3: 'Default Reason', col4: device.defaultReasonId, col5: 'Valve Model', col6: getValidNumber(device.valveModel), col7: 'Auto Normal Flow', col8: device.autoNormalFlow, col9: 'Equipment Volume', col10: equipment ? equipment.equipmentVolume : '', col11: 'Auto 40 C', col12: device.auto40CV || '0' },
                        { col1: 'HeaderID', col2: device.headerId, col3: 'SplitOpeningRange', col4: device.splitOpeningRange, col5: 'Valve Size IN', col6: getValidNumber(device.valveSizeIn), col7: 'Auto Max Flow', col8: device.autoMaxFlow, col9: 'Equipment Volume UoM', col10: equipment ? equipment.equipmentVolumeUom : '', col11: 'Auto 50 C', col12: device.auto50CV || '0' },
                        { col1: 'Header Type', col2: header ? header.headerType : '', col3: 'SplitClosingRange', col4: device.splitClosingRange, col5: 'Valve Type', col6: getValidNumber(device.valveType), col7: 'Auto Rated Flow', col8: device.autoRatedFlow, col9: 'Cost$ Per MMBTU', col10: annualPrice ? annualPrice.costPerMMBtu : '', col11: 'Auto 60 C', col12: device.auto60CV || '0' },
                        { col1: 'Calc Method', col2: device.calcMethod, col3: 'Design Inlet Pressure PSIA', col4: device.designInletPressurePSIA, col5: 'Constant Flow Qty', col6: device.constantFlowQty, col7: 'AUTO_4flowUnits', col8: device.rated4FlowUnits, col9: 'Cost$ Per BBL', col10: annualPrice ? annualPrice.costPerBBL : '', col11: 'Auto 70 C', col12: device.auto70CV || '0' },
                        { col1: 'DataCollectionMode', col2: device.dataCollectionMode, col3: 'Design Outlet Pressure PSIA', col4: device.designOutletPressurePSIA, col5: 'Constant Flow Qty UOM', col6: getValidNumber(device.constantFlowQtyUom), col7: 'MixedLHV', col8: facilityFeed ? facilityFeed.lhv_BtuScf : '', col9: '', col10: '', col11: 'Auto 80 C', col12: device.auto80CV || '0' },
                        { col1: 'DeviceType', col2: device.deviceTypeId, col3: 'Design Inlet Temp DEGF', col4: device.designInletTempDegF, col5: 'Auto Min Flow CV', col6: getValidNumber(device.autoMinFlowCV), col7: 'CGR', col8: equipment ? equipment.defaultCGR : '', col9: '', col10: '', col11: 'Auto 90 C', col12: device.auto90CV || '0' },
                        { col1: 'Device Classification', col2: device.classificationId, col3: 'Gas Sp. Gravity', col4: device.gasSpgravity, col5: 'Auto Normal Flow CV', col6: getValidNumber(device.autoNormalFlowCV), col7: 'Remarks', col8: device.remarks || '0', col9: '', col10: '', col11: 'Auto 100 C', col12: device.auto100CV || '0' }
                    ];

                    setFmsData(fmsData);
                    associateColDef(device.materialState);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.error('Please select valid options.');
        }
    };

    const associateColDef = (state) => {
        if (state === 'GAS') {
            setAssociatedTagsColumnDefs([
                {
                    headerName: 'Tag Information',
                    width:'600px',
                    children: [
                        { headerName: 'Tag Name', field: 'tagName', width: 250, filter: true },
                        { headerName: 'Reading Type', field: 'readingType', width: 150, filter: true }
                    ]
                },
                {
                    headerName: 'Associated Tags',
                    width: '1200px',
                    children: [
                        { headerName: 'Min', field: 'min', width: 100, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'Max', field: 'max', width: 100, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'Default', field: 'defaultValue', width: 120, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'UoM', field: 'uom', width: 150, filter: true }
                    ]
                },
                {
                    headerName: 'Additional Properties',
                    width: '1200px',
                    children: [
                        { headerName: 'LIMS Composition', field: 'limsComposition', width: 270, filter: true },
                        { headerName: 'LIMS Property', field: 'limsProperty', width: 250, filter: true },
                        { headerName: 'MW g/mol', field: 'mw_gmol', width: 155, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'LHV BTU/SCF', field: 'lhv_Btuscf', width: 170, filter: true, valueFormatter: params => params.value || '0' }
                    ]
                }
            ]);
        } else if (state === 'LIQUID') {
            setAssociatedTagsColumnDefs([
                {
                    headerName: 'Tag Information',
                    children: [
                        { headerName: 'Tag Name', field: 'tagName', width: 150, filter: true },
                        { headerName: 'Reading Type', field: 'readingType', width: 150, filter: true }
                    ]
                },
                {
                    headerName: 'Associated Tags',
                    children: [
                        { headerName: 'Min', field: 'min', width: 100, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'Max', field: 'max', width: 100, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'Default', field: 'defaultValue', width: 150, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'UoM', field: 'uom', width: 150, filter: true }
                    ]
                },
                {
                    headerName: 'Additional Properties',
                    children: [
                        { headerName: 'LIMS Composition', field: 'limsComposition', width: 170, filter: true },
                        { headerName: 'LIMS Property', field: 'limsProperty', width: 150, filter: true },
                        { headerName: 'LHV', field: 'lhv', width: 150, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'LHV UOM', field: 'lhvUom', width: 150, filter: true },
                        { headerName: 'Density', field: 'density', width: 150, filter: true, valueFormatter: params => params.value || '0' },
                        { headerName: 'Density UOM', field: 'densityUom', width: 150, filter: true }
                    ]
                }
            ]);
        }
    };
    

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = ghgErrorsData.filter(item =>
            String(item.transactionDateTime).toLowerCase().includes(lowercasedSearch) ||
            String(item.deviceId).toLowerCase().includes(lowercasedSearch) ||
            String(item.plantId).toLowerCase().includes(lowercasedSearch) ||
            String(item.facilityId).toLowerCase().includes(lowercasedSearch) ||
            String(item.tagname).toLowerCase().includes(lowercasedSearch) ||
            String(item.errorComments).toLowerCase().includes(lowercasedSearch)
        );
        setFilteredData(filtered);
    }, [search, ghgErrorsData]);

    const handleSearch = (event) => setSearch(event.target.value);
    const handleSelectedDateChange = (date) => setSelectedDate(date);

    return (
        <div className="main-content">
            <div className="breadcrumb mt-3">
                <h1>FMS Health Check</h1>
                <ul>
                    <li>Health Check</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row">
                <div className="col-sm-2 form-group">
                    <label>Select Facility</label>
                    <select
                        className="form-control"
                        value={selectedFacilityId}
                        onChange={handleFacilityChange}
                    >
                        <option value="-1">All Facilities</option>
                        {facilities.map(fac => (
                            <option key={fac.facilityId} value={fac.facilityId}>
                                {fac.facilityName}
                            </option>
                        ))}
                    </select>
                </div>
                <PlantSelector
                    selectedFacilityId={selectedFacilityId}
                    selectedPlantId={selectedPlantId}
                    onChange={setSelectedPlantId}
                    disabled={selectedFacilityId === '-1'}
                />
                <DataCollectionModeSelector
                    selectedModeId={selectedModeId}
                    onChange={setSelectedModeId}
                    disabled={selectedFacilityId === '-1'}
                />
                <div className="col-sm-2">
                    <label>Select Date</label>
                    <div className={'datepicker-parent'}>
                        <DatePicker
                            className="form-control"
                            selected={selectedDate}
                            onChange={handleSelectedDateChange}
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>
                <DeviceSelector
                    selectedDeviceId={selectedDeviceId}
                    onChange={setSelectedDeviceId}
                    disabled={selectedModeId === '-1'}
                />
            </div>
            <div className="row mt-4">
                <div className="col-sm-12">
                    <div className="table-responsive">
                        {/* <h5 className='border text-center py-2'>Source Information</h5> */}
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={fmsData}
                                columnDefs={sourceColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true, filter: true, }}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-4">
                        <h5 className='border text-center py-2'>Associated Tags</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={associatedTagsData}
                                columnDefs={associatedTagsColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-4">
                        <h5 className='border text-center py-2'>Last 6 days flaring</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={dailyTxDeviceData}
                                columnDefs={flaringColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-4">
                        <h5 className='border text-center py-2'>Data Source Alerts</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={ghgErrorsData}
                                columnDefs={alertsColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

