import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config';
const DataCollectionModeSelector = ({ selectedModeId, onChange }) => {
    const [modeData, setModeData] = useState([]);

    useEffect(() => {
        const fetchModeData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/master/code-master/dataCollectionMode`, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setModeData(data);
            } catch (error) {
                console.error('Failed to fetch facility data:', error);
            }
        };

        fetchModeData();
    }, []);

    return (
        <div className='col-md-2'>
            <label>Data Collection Mode</label>
            <select
                name='facility'
                className='form-control'
                value={selectedModeId}
                onChange={(e) => onChange(e.target.value)}
            >
                <option value={'FIXEDFLOW'}>Data Collection Mode</option>
                {modeData.map((data) => (
                    <option key={data.code} value={data.code}>
                        {data.description}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DataCollectionModeSelector;
