import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { formatWithIndianThousandsSeparatorForExportData } from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';
import formatDateTime from '../../common/formatDateTime';

export default function GreenHouseAudits() {
  const [facilityData, setFacilityData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [greenHouseEmissionReasonsData, setGreenHouseEmissionReasonsData] =
    useState([]);
  const [startDate, setStartDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [selectedPlantId, setSelectedPlantId] = useState(-1);
  const [filteredPlantData, setFilteredPlantData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);

  useEffect(() => {
    fetchFacilityData();
    fetchPlantData();
  }, []);

  useEffect(() => {
    if (selectedFacilityId !== -1) {
      const filteredPlants = plantData.filter(
        (plant) => plant.facilityId == selectedFacilityId
      );
      setFilteredPlantData(filteredPlants);
    } else {
      setPlantData([]);
      setSelectedPlantId(-1);
    }
  }, [selectedFacilityId]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const fetchFacilityData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/home/facilities`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFacilityData(data.filter((t) => t.facilityCategory != 'AMMNET'));
    } catch (error) {
      console.error('Failed to fetch facility data:', error);
    }
  };

  const fetchPlantData = async (facilityId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/home/plants?facilityId=${facilityId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setPlantData(data);
    } catch (error) {
      console.error('Failed to fetch plant data:', error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
    console.warn(e.target.value);
  };

  const handlePlantChange = (e) => {
    setSelectedPlantId(e.target.value);
  };

  const handleGreenHouseEmissionReasonsButtonClick = () => {
    if (
      startDate instanceof Date &&
      endDate instanceof Date &&
      selectedFacilityId !== ''
    ) {
      setIsLoading(true); // Set loading state to true before fetching data
      const formattedStartDate = format(startDate, 'yyyy-MM-dd 00:00:00');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd 23:59:59');
      const apiUrl = `${API_BASE_URL}/reports/GHGAuditEmissions?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&facilityId=${selectedFacilityId}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          setGreenHouseEmissionReasonsData(data);
          setFilteredData(data); // Update the filtered data as well
          setIsLoading(false); // Set loading state to false after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsLoading(false); // Set loading state to false if there's an error
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    let filtered = greenHouseEmissionReasonsData.filter(
      (item) =>
        String(item.reasonDescription)
          .toLowerCase()
          .includes(lowercasedSearch) ||
        String(item.pollutant).toLowerCase().includes(lowercasedSearch) ||
        String(item.emissions).toLowerCase().includes(lowercasedSearch) ||
        String(item.uomId).toLowerCase().includes(lowercasedSearch)
    );

    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered = [
        {
          reasonDescription: '',
          pollutant: '',
          emissions: '',
          uomId: '',
        },
      ];
    }

    setFilteredData(filtered);

    // Create exportFilteredData according to the columns order
    const exportData = filtered.map((item) => ({
      'Adj Reasons': item.reasonDescription,
      Pollutant: item.pollutant,
      Emissions: formatWithIndianThousandsSeparatorForExportData(
        item.emissions ? item.emissions.toFixed(0) : item.emissions
      ),
      UoM: item.uomId,
    }));
    setExportFilteredData(exportData);
  }, [search, greenHouseEmissionReasonsData]);

  const columns = [
    {
      name: 'Txt Date & Time',
      selector: (row) => row.txDateTime,
      cell: (row) => (row.txDateTime ? formatDateTime(row.txDateTime) : ''),
      width: '150px',
      sortable: true,
    },
    { name: 'Device ID', selector: (row) => row.deviceID, sortable: true },
    { name: 'Facility ID', selector: (row) => row.facilityID, sortable: true },
    {
      name: 'Material State',
      selector: (row) => row.materialState,
      sortable: true,
    },
    { name: 'Pollutant', selector: (row) => row.pollutant, sortable: true },
    { name: 'UoM', selector: (row) => row.uomID, sortable: true },
    {
      name: 'Emission',
      selector: (row) => row.emission,
      sortable: true,
      right: true,
    },
    { name: 'Category ID', selector: (row) => row.categoryID, sortable: true },
    {
      name: 'adjEmission',
      selector: (row) => row.adjEmission,
      right: true,
      sortable: true,
    },
    { name: 'Engineer ID', selector: (row) => row.engineerID, sortable: true },
    {
      name: 'User Comments',
      selector: (row) => row.userComments,
      sortable: true,
    },
    {
      name: 'Comment Date',
      selector: (row) => row.commentDate,
      cell: (row) => (row.commentDate ? formatDateTime(row.commentDate) : ''),

      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '30px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const fileName = `GHG_Emission_Reasons${new Date().toLocaleDateString()}`;

  const handleStartDateFocus = () =>
    setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    {
      label: 'Facility Name',
      value:
        selectedFacilityId != -1
          ? facilityData.find(
              (facility) => facility.facilityId == selectedFacilityId
            )?.facilityName
          : 'All Facilities',
    },
    {
      label: 'Plant Name',
      value:
        selectedPlantId != -1 && selectedFacilityId != -1
          ? filteredPlantData.find((plant) => plant.plantId == selectedPlantId)
              ?.plantName
          : 'All Plants',
    },
    {
      label: 'Start Date',
      value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected',
    },
    {
      label: 'End Date',
      value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected',
    },
  ];

  const title = 'Emission Reasons Report';

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-3'>
        <h1>GHG Audits</h1>
        <ul>
          <li>Reports</li>
          <li>Greenhouse Gases</li>
        </ul>
        <div className='separator-breadcrumb border-top' />
      </div>
      <div className='breadcrumb mt-3'>
        <div className='row'>
          <div className='col-md-2'>
            <label>Select Facility</label>
            <select
              name='facility'
              className='form-control'
              value={selectedFacilityId}
              onChange={handleFacilityChange}
            >
              <option value={-1}>All Facilities</option>
              {facilityData.map((facility) => (
                <option key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </option>
              ))}
            </select>
          </div>

          <div className='col-md-2'>
            <label>Select Plant</label>
            <select
              name='plant'
              className='form-control'
              value={selectedPlantId}
              onChange={handlePlantChange}
              disabled={selectedFacilityId === -1}
            >
              <option value={-1}>All Plants</option>
              {filteredPlantData.map((plant) => (
                <option key={plant.plantId} value={plant.plantId}>
                  {plant.plantName}
                </option>
              ))}
            </select>
          </div>

          <div className='col-md-2'>
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText='Start Date'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText='End Date'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>
          <div className='col-md-1 generate_report_button'>
            <button
              className='btn btn-primary'
              onClick={handleGreenHouseEmissionReasonsButtonClick}
            >
              {BUTTON_LABELS.REPORT_BUTTON}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className='row mb-4'>
        <div className='col-md-12 mb-3'>
          <div className='card text-left'>
            <div className='card-body'>
              {/* <h4 className="card-title mb-3">Emission Reasons</h4> */}
              <div className='row'>
                <div className='col-md-10'>
                  {filteredData.length > 0 && (
                    <ExportComponent
                      data={exportFilteredData}
                      columns={columns}
                      headerData={headerData}
                      fileName={fileName}
                      title={title}
                    />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type='text'
                    placeholder='Search'
                    className='form-control mb-3'
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className='table-responsive'>
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of col*/}
      </div>
    </div>
  );
}
