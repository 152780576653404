import React, { useState, useEffect, useRef } from 'react';
import DataCorrectionService from '../services/DataCorrectionService';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Modal, Card } from 'react-bootstrap';
import EditGhgValidation from './EditGhgValidation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';
import ExportComponent from '../../../../common/ExportComponent';
import { format } from 'date-fns';
import formatDateTime from '../../../../common/formatDateTime';

const GhgValidationList = () => {
  const [ghgValidationItems, setGhgValidationItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [exportFilteredData, setExportFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const datepickerRef = useRef();
  const gridRef = useRef();

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      fetchGhgValidationData(formattedDate);
    }
  }, [selectedDate]);

  const fetchGhgValidationData = async (date = '') => {
    setIsLoading(true);
    try {
      const response = await DataCorrectionService.getEmissionValidation(date);
      setGhgValidationItems(response.data);
      setFilteredData(response.data);
      prepareExportData(response.data);
    } catch (error) {
      console.error('Failed to fetch GHG validation data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const prepareExportData = (data) => {
    const exportData = data.map(item => ({
      'Tx Date & Time': item.txDateTime ? formatDateTime(item.txDateTime) : '',
      'Source ID': item.deviceID,
      'Header ID': item.headerID,
      'Facility ID': item.facilityID,
      'Plant ID': item.plantID,
      'Pollutant': item.pollutant,
      'UoM': item.uomID,
      'Emission': formatWithIndianThousandsSeparatorForExportData(item.emission ? item.emission.toFixed(0) : item.emission),
      'Emission Avg': formatWithIndianThousandsSeparatorForExportData(item.emissionAvg ? item.emissionAvg.toFixed(0) : item.emissionAvg),
      'Emission Min': formatWithIndianThousandsSeparatorForExportData(item.emissionMin ? item.emissionMin.toFixed(0) : item.emissionMin),
      'Emission Max': formatWithIndianThousandsSeparatorForExportData(item.emissionMax ? item.emissionMax.toFixed(0) : item.emissionMax),
      'Reason': item.reasonID,
      'Adj Emission': formatWithIndianThousandsSeparatorForExportData(item.adjEmission ? item.adjEmission.toFixed(0) : item.adjEmission),
      'Adj Avg Emission': formatWithIndianThousandsSeparatorForExportData(item.adjAvgEmission ? item.adjAvgEmission.toFixed(0) : item.adjAvgEmission),
      'Adj Emission Min': formatWithIndianThousandsSeparatorForExportData(item.adjEmissionMin ? item.adjEmissionMin.toFixed(0) : item.adjEmissionMin),
      'Adj Emission Max': formatWithIndianThousandsSeparatorForExportData(item.adjEmissionMax ? item.adjEmissionMax.toFixed(0) : item.adjEmissionMax),
      'Adj Reason': item.adjReasonID,
      'Engineer ID': item.engineerID,
      'User Comments': item.userComments
    }));
    setExportFilteredData(exportData);
  };

  const handleEdit = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onFilterTextBoxChanged = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);
  };

  // AG Grid Column Definitions
  const columnDefs = [
    {
      field: 'txDateTime',
      headerName: 'Tx Date & Time',
      pinned: 'left',
      width: 150,
      valueFormatter: params => params.value ? formatDateTime(params.value) : ''
    },
    {
      field: 'deviceID',
      headerName: 'Source ID',
      pinned: 'left',
      width: 120
    },
    {
      field: 'headerID',
      headerName: 'Header ID',
      pinned: 'left',
      width: 120
    },
    { field: 'facilityID', headerName: 'Facility ID', width: 120 },
    { field: 'plantID', headerName: 'Plant ID', width: 120 },
    { field: 'pollutant', headerName: 'Pollutant', width: 120 },
    { field: 'uomID', headerName: 'UoM', width: 100 },
    {
      field: 'emission',
      headerName: 'Emission',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    {
      field: 'emissionAvg',
      headerName: 'Emission Avg',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right

    },
    {
      field: 'emissionMin',
      headerName: 'Emission Min',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    {
      field: 'emissionMax',
      headerName: 'Emission Max',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    { field: 'reasonID', headerName: 'Reason', width: 120 },
    {
      field: 'adjEmission',
      headerName: 'Adj Emission',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    {
      field: 'adjAvgEmission',
      headerName: 'Adj Avg Emission',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    {
      field: 'adjEmissionMin',
      headerName: 'Adj Emission Min',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
      cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    {
      field: 'adjEmissionMax',
      headerName: 'Adj Emission Max',
      width: 130,
      valueFormatter: params => (params.value ? params.value.toFixed(0) : params.value),
       cellStyle: { textAlign: 'right' } // Aligning the cell content to the right
    },
    { field: 'adjReasonID', headerName: 'Adj Reason', width: 120 },
    { field: 'engineerID', headerName: 'Engineer ID', width: 120 },
    { field: 'userComments', headerName: 'User Comments', width: 150 },
    {
      headerName: 'Edit',
      width: 75,
      pinned: 'right',
      cellRenderer: params => {
        if (params.data?.txDateTime) {
          return (
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={() => handleEdit(params.data)}
              style={{ cursor: 'pointer', color: 'blue' }}
            />
          );
        }
        return null;
      }
    }
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
  };

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight: 40,
    headerHeight: 40,
    suppressRowClickSelection: true,
    enableCellTextSelection: true
  };

  const fileName = `Ghg_Validation_${new Date().toLocaleDateString()}`;
  
  const headerData = [
    { label: 'Select Date Range', value: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Ghg Validations";

  return (
    <div className="main-content">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className='breadcrumb mt-3'>
            <h1>GHG Validations</h1>
            <ul>
              <li>Data Corrections</li>
              <li>GHG</li>
            </ul>
            <div className="separator-breadcrumb border-top" />
          </div>
          <div className='row'>
            <div className="col-sm-3">
              <label htmlFor="dateRange" className="control-label">Select Date Range</label>
              <div className={'datepicker-parent'}>
                <DatePicker
                  ref={datepickerRef}
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select a date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-sm-12">
              {isLoading && <div>Loading...</div>}
              {!isLoading && (
                <>
                  <div className='row mt-3'>
                    <div className='col-md-10'>
                      {filteredData.length > 0 && (
                        <ExportComponent 
                          data={exportFilteredData} 
                          columns={columnDefs} 
                          headerData={headerData} 
                          fileName={fileName} 
                          title={title} 
                        />
                      )}
                    </div>
                    <div className='col-md-2'>
                      {/* <input
                        type="text"
                        placeholder="Search"
                        className="form-control mb-3"
                        onChange={onFilterTextBoxChanged}
                      /> */}
                    </div>
                  </div>
                  <div 
                    className="ag-theme-alpine" 
                    style={{ 
                      height: '500px',
                      width: '100%'
                    }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={filteredData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      gridOptions={gridOptions}
                      animateRows={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit GHG Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditGhgValidation 
            data={selectedData} 
            onClose={() => setShowModal(false)} 
            onSave={() => {
              if (selectedDate) {
                const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
                fetchGhgValidationData(formattedDate);
              }
            }} 
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GhgValidationList;
