// components/deviceSelector.js
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config';
const DeviceSelector = ({
  selecteDeviceId,
  onChange,
  facilityID = null,
  plantID = null,
  dataCollectionMode = null,
  type = 'EMS',
}) => {
  const [deviceData, setDeviceData] = useState([]);
  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/configuration/data/device/all`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(type, 'type');

        let filteredData = data;

        if (facilityID) {
          filteredData = filteredData.filter(
            (item) => item.facilityId === facilityID
          );
        }
        if (plantID) {
          filteredData = filteredData.filter(
            (item) => item.plantId === plantID
          );
        }
        if (dataCollectionMode) {
          filteredData = filteredData.filter(
            (item) => item.dataCollectionMode === dataCollectionMode
          );
        }
        if (type === 'GHG') {
          filteredData = filteredData.filter(
            (item) => item.deviceEmissionType === 'GHG'
          );
        }
        console.log(filteredData);
        setDeviceData(filteredData);
      } catch (error) {
        console.error('Failed to fetch device data:', error);
      }
    };
    fetchDeviceData();
  }, [facilityID, plantID, dataCollectionMode, type]);

  return (
    <div className='col-md-2'>
      <label>Select Source</label>
      <select
        name='device'
        className='form-control'
        value={selecteDeviceId}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value={''}>Select Source</option>
        {deviceData.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.deviceId}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DeviceSelector;
